import './App.css';
import MovieBus from "./content/main/MovieBus";

function App() {

    return (
        <MovieBus/>
    );
}

export default App;
